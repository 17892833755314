<template>
  <div class="flex [&>div]:flex-1 h-2 relative">
    <div class="absolute inset-0 bg-gradient-to-b from-[#000]/20 to-transparent"></div>
    <div class="bg-[#2CBFF7]"></div>
    <div class="bg-[#C13626]"></div>
    <div class="bg-[#CE6E23]"></div>
    <div class="bg-[#27C9F4]"></div>
    <div class="bg-[#7AE3FF]"></div>
    <div class="bg-[#035DD3]"></div>
    <div class="bg-[#EB3824]"></div>
    <div class="bg-[#1CA7EA]"></div>
  </div>
</template>
